<template lang="pug">
  layout-access
    router-view
</template>

<script>
import LayoutAccess from '@components/layouts/Access'

export default {
  name: 'account',
  components: {
    LayoutAccess,
  },
}
</script>
